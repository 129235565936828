





















































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import VueUeditorWrap from "vue-ueditor-wrap";
import { LibraryManagementService } from '@/services/library-management-service';

@Component({
  components: {
    VueUeditorWrap,
  }
})
export default class addDocument extends Vue {
  public myConfig = {
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: 500,
    // 初始容器宽度
    initialFrameWidth: "100%",
    // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
    //serverUrl: '/api/application/ueditor/config',
     serverUrl: '/wecom-buddy-backend/api/article/admin/richText/upload',
    catchRemoteImageEnable:true,
    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
    UEDITOR_HOME_URL: "/ueditor/",
    toolbars: [[
      'fullscreen', 'source', '|', 'undo', 'redo', 'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript',
      'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', 'forecolor',
      'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc',
      'fontfamily', 'fontsize', 'simpleupload', 'insertimage', 'emotion', /*'scrawl',*/
            /*'music',*/ 'insertvideo', 'insertaudio', 'attachment', 'map',
      'rowspacingtop', 'rowspacingbottom', 'lineheight', 'link', 'unlink'
    ]],
    'fontfamily': [
      { label: '', name: 'songti', val: '宋体,SimSun' },
      { label: '仿宋', name: 'fangsong', val: '仿宋,FangSong' },
      { label: '仿宋_GB2312', name: 'fangsong', val: '仿宋_GB2312,FangSong' },
      { label: '', name: 'kaiti', val: '楷体,楷体_GB2312, SimKai' },
      { label: '', name: 'yahei', val: '微软雅黑,Microsoft YaHei' },
      { label: '', name: 'heiti', val: '黑体, SimHei' },
      { label: '', name: 'lishu', val: '隶书, SimLi' },
      { label: '', name: 'andaleMono', val: 'andale mono' },
      { label: '', name: 'arial', val: 'arial, helvetica,sans-serif' },
      { label: '', name: 'arialBlack', val: 'arial black,avant garde' },
      { label: '', name: 'comicSansMs', val: 'comic sans ms' },
      { label: '', name: 'impact', val: 'impact,chicago' },
      { label: '', name: 'timesNewRoman', val: 'times new roman' }
    ]
  };
  public ishowImage: Boolean = false;//默认是没有选中数据tableData==0
  public title: String = "选择图文素材";
  public labelPosition: String = 'top';
  public firstMenuName: String = '';
  public scenariosTitle: string = '';
  public form: any = {
    toall: 1,
    sourceType: null,
    articleName: '',
    articleContent: '',
    sortNumber: '',
    firstMenuId: '',
    secondMenuId: '',
    appOrScenarioId: '',
  };
  //表单校验
  public rules: any = {
    sourceType: [
      { required: true, message: '请选择所属类型', trigger: ['blur', 'change'] }],
    appOrScenarioId: [
      { required: true, message: '请选择应用/业务场景名称', trigger:  ['blur', 'change'] }
    ],
    articleName: [
      { required: true, message: '请输入文章名称', trigger: ['blur', 'change'] }
    ],
    sortNumber: [
      { required: true, message: '请输入文章排序', trigger: ['blur', 'change'] }
    ],
    secondMenuId: [
      { required: true, message: '请选择目录名称', trigger: ['blur', 'change'] }
    ]
  }
  public type: any = ''; //判断是修改还是添加
  public readonly: boolean = true;
  public loading: boolean = false;
  private applications: any[] = []; //存储应用/业务场景名称
  private menuNameList: any[] = []; //菜单名称集合
  public historyUrl: string = '';

  @Inject(LibraryManagementService) private libraryManagementService!: LibraryManagementService;

  public async created(): Promise<void> {
    this.type = JSON.parse(localStorage.getItem('addDocument') || '')
    this.type > 0 && this.initForm();
  }
  //修改页面初始化数据
  public async initForm() {
    const res = await this.libraryManagementService.queryArticleInfo(this.type);
    this.historyUrl = `${location.origin}/wecom-buddy-front/article/preview/`+res.id;
    await this.sourceTypeChanged(res.sourceType);
    await this.appOrScenarioNameChanged(res.appOrScenarioId);
    this.form = res;
    this.secondMenuIdChanged();
  }
  //根据所属类型获取应用/业务场景名称
  public async sourceTypeChanged(val: any) {
    this.form.appOrScenarioId = '';
    const param = {
      sourceType: val
    }
    const res = await this.libraryManagementService.queryAppOrScenarioByType(param);
    this.applications = res;
  }
  //根据应用/业务场景获取菜单名称
  public async appOrScenarioNameChanged(val: any) {
    this.form.secondMenuId = '';
    this.scenariosTitle = '';
    const param = {
      appOrScenarioId: val
    }
    const res = await this.libraryManagementService.querySecondMenuByAppOrScenarioId(param);
    this.menuNameList = res;
    this.scenariosTitle = this.applications.filter(item => item.id == val)[0].scenariosTitle;
  }
  public menuNameFilter(id: number){
    return this.menuNameList.filter(item => {
      return item.id == id;
    })
  }
  //根据目录名称填入过滤关键字
  public secondMenuIdChanged() {
    this.firstMenuName = this.menuNameFilter(this.form.secondMenuId)[0].firstMenuName || '';
  }
  //保存
  public async submitForm(formName: any) {
    const param = {
      firstMenuId: this.menuNameFilter(this.form.secondMenuId)[0].firstMenuId
    }
    const addRef = (this.$refs.form as any);

    addRef.validate(async (valid: any) => {
      if (valid) {
        try {
          if(this.type != '-1') {
            await this.libraryManagementService.updateArticleById({...this.form, ...param, id: this.type});
          } else {
            await this.libraryManagementService.articleAdd({...this.form, ...param});
          }
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.$router.push("/documentManagement");
        }catch(error){
          console.error('Failed to save:', error);
        }
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }
  //取消
  public async handleGoBack(): Promise<void> {
    await this.$confirm('当前页面信息将会丢失，请确认是否离开?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
    this.$router.go(-1)
  }
  //复制
  public copyUrl() {
    var oInput = document.createElement('input');
    oInput.value = this.historyUrl;
    document.body.appendChild(oInput);
    oInput.select();
    document.execCommand("Copy");
    this.$message({
      type: 'success',
      message: '复制成功'
    });
    oInput.remove();
  }
}
